import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CanDeactivate } from "@angular/router";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: "root",
})
export class ChangeGuardService
  implements CanDeactivate<ComponentCanDeactivate> {
  constructor() {}
  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: import("@angular/router").ActivatedRouteSnapshot,
    currentState: import("@angular/router").RouterStateSnapshot,
    nextState?: import("@angular/router").RouterStateSnapshot
  ):
    | boolean
    | import("@angular/router").UrlTree
    | Observable<boolean | import("@angular/router").UrlTree>
    | Promise<boolean | import("@angular/router").UrlTree> {
    return component.canDeactivate()
      ? true
      : confirm(
          "WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes."
        );
  }
}
