import { Injectable, ɵConsole } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppProperties } from "../CognianModels/AppProperties";

@Injectable({
  providedIn: "root",
})
export class CogWebHandlerService {
  options = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  constructor(private http: HttpClient, private appdata: AppProperties) {}

  GetRequest(path: string, skipAuth: boolean = false): Observable<any> {
    //  if(!skipAuth)
    // this.options= { headers: new HttpHeaders({'Content-Type':'application/json','Authorization': "Bearer "+this.appdata.userToken})};

    return this.http.get(this.appdata.EdnpointUrl + "/" + path, this.options);
  }

  MakePost(
    path: string,
    objectToPost: any,
    skipAuth: boolean = false
  ): Observable<any> {
    //  if(!skipAuth)
    // this.options= { headers: new HttpHeaders({'Content-Type':'application/json','Authorization':"Bearer "+this.appdata.userToken})};
    console.log("Path is " + this.appdata.EdnpointUrl + "/" + path);
    console.log("Json value is " + JSON.stringify(objectToPost));
    return this.http.post<any>(
      this.appdata.EdnpointUrl + "/" + path,
      JSON.stringify(objectToPost),
      this.options
    );
  }

  PutRequest(
    path: string,
    objectToPost: any,
    skipAuth: boolean = false
  ): Observable<any> {
    //  if(!skipAuth)
    // this.options= { headers: new HttpHeaders({'Content-Type':'application/json','Authorization':"Bearer "+this.appdata.userToken})};
    return this.http.put(
      this.appdata.EdnpointUrl + "/" + path,
      JSON.stringify(objectToPost),
      this.options
    );
  }
  DeleteRequest(path: string, skipAuth: boolean = false): Observable<any> {
    //  if(!skipAuth)
    // this.options= { headers: new HttpHeaders({'Content-Type':'application/json','Authorization': "Bearer "+this.appdata.userToken})};
    return this.http.delete(
      this.appdata.EdnpointUrl + "/" + path,
      this.options
    );
  }
}
