import { Injectable } from "@angular/core";
import { AppProperties } from "../CognianModels/AppProperties";
import { CogWebHandlerService } from "./cog-web-handler.service";
import { stringify } from "@angular/compiler/src/util";
import { Observable } from "rxjs";
import { School } from "app/CognianModels/school";

@Injectable({
  providedIn: "root",
})
export class CognianCommonService {
  constructor(
    private appdata: AppProperties,
    private webHandler: CogWebHandlerService
  ) {}

  public CheckServerConnectivity() {
    return this.webHandler.GetRequest("Test", true);
  }
  public CompileUserGroupList() {
    let userGroups = "-1$" + this.appdata.loggedInUser.PersonType + "$";
    this.appdata.schoolGroup.forEach((element) => {
      userGroups += element.GroupID + "$";
    });
    return userGroups;
  }

  public GetSchoolInfo(schoolId): Observable<School> {
    return this.webHandler.GetRequest("School/" + schoolId + "/GetSchoolInfo");
  }
  public getSchoolCalendar() {
    return this.webHandler.GetRequest(
      "School/" + this.appdata.schoolID + "/GetCalendar"
    );
  }

  public postSchoolCalendar(schoolCalendar) {
    return this.webHandler.MakePost(
      "School/" + this.appdata.schoolID,
      schoolCalendar
    );
  }
}
