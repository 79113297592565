import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LoginCognianComponent } from "./CognianPages/login-cognian/login-cognian.component";
import { AuthGuard } from "./CognianServices/auth-guard.service";
import { RegisterComponent } from "./CognianPages/register/register.component";
import { ForgotPasswordComponent } from "./CognianPages/forgot-password/forgot-password.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "newsfeed",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/admin-layout/admin-layout.module#AdminLayoutModule",
      },
    ],
  },
  {
    path: "register",
    component: RegisterComponent,
  },
  {
    path: "reset-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "login",
    component: LoginCognianComponent,
    // children: [{
    //     path: 'login', //login page still under "pages" folder as the original code
    //     loadChildren: './login'
    //   }]
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
