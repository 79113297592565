import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CogAuthService } from './cog-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    if(this.authService.isLoggedIn())
    return true;
    else
    console.log("Routing");
    this.router.navigate(['./login']);
    return false;
  }
  

  constructor(private authService:CogAuthService,private router:Router) { }
}
