import { Injectable } from "@angular/core";
import { CogWebHandlerService } from "./cog-web-handler.service";
import { AppProperties } from "app/CognianModels/AppProperties";
import { CognianCommonService } from "./cognian.common.service";
import { Users } from "app/CognianModels/User.Model";
import { ConfirmationType } from "app/CognianModels/Enums/ConfirmationType";
import { PasswordResetModule } from "app/CognianModels/PasswordResetModule";
import { Person } from "app/CognianModels/Person";

@Injectable({
  providedIn: "root",
})
export class UserService {
  ControlerName: String = "User";
  constructor(
    private webHandler: CogWebHandlerService,
    private appdata: AppProperties,
    private common: CognianCommonService
  ) {}
  public generateRegCode(user: Person, confType) {
    return this.webHandler.MakePost(
      this.ControlerName + "/" + confType.toString() + "/ResetPassword",
      user
    );
  }
  public checkConfirmationStatus(code: string, personID: string) {
    return this.webHandler.GetRequest(
      this.ControlerName + "/" + code + "/" + personID + "/Confirm"
    );
  }
  public resetPassword(user: Users) {
    return this.webHandler.MakePost(this.ControlerName + "/ResetPass", user);
  }
}
