import { Injectable } from '@angular/core';
import { CogWebHandlerService } from './cog-web-handler.service';
import { AppProperties } from '../CognianModels/AppProperties';

@Injectable({
  providedIn: 'root'
})
export class CogStudentService {
ControllerName:string="Student";
  constructor(private webHandler:CogWebHandlerService,private appdata:AppProperties) { }
  
  public GetStudent()
  {
    console.log("Making Call");
    console.log(this.appdata.loggedInUser)
    console.log(this.appdata.loggedInUser.PersonID)
    return this.webHandler.GetRequest(this.ControllerName+"/"+this.appdata.loggedInUser.PersonID);

  }
}
