import { Injectable } from "@angular/core";
import { CogWebHandlerService } from "./cog-web-handler.service";
import { AppProperties } from "app/CognianModels/AppProperties";
import { CognianCommonService } from "./cognian.common.service";
import { Users } from "app/CognianModels/User.Model";
import { Person } from "app/CognianModels/Person";

@Injectable({
  providedIn: "root",
})
export class RegistrationService {
  ControllerName: string = "Registration";
  constructor(
    private webHandler: CogWebHandlerService,
    private appdata: AppProperties,
    private common: CognianCommonService
  ) {}

  public checkUserHasAccount(personID) {
    return this.webHandler.GetRequest(
      this.ControllerName + "/" + personID + "/CheckAcc",
      true
    );
  }

  public getUserAssociates(personID) {
    return this.webHandler.GetRequest(this.ControllerName + "/" + personID);
  }

  public generateRegistrationCode(user: Person) {
    return this.webHandler.MakePost(this.ControllerName + "/CreateCode", user);
  }
  public validateUserCode(code: string) {
    return this.webHandler.GetRequest(
      this.ControllerName + "/" + code + "/CheckCode"
    );
  }

  public confirmUserCode(code: string) {
    return this.webHandler.MakePost(this.ControllerName + "/ConfirmCode", code);
  }

  public registerUser(user: Users) {
    return this.webHandler.MakePost(this.ControllerName, user);
  }
  public getUserByEmail(email: string) {
    return this.webHandler.GetRequest(
      this.ControllerName + "/" + email + "/getByEmail"
    );
  }
}
