import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppProperties } from 'app/CognianModels/AppProperties';

@Component({
  selector: 'app-cog-loader',
  templateUrl: './cog-loader.component.html',
  styleUrls: ['./cog-loader.component.scss']
})
export class CogLoaderComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CogLoaderComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,private appdata:AppProperties)
     { }

  ngOnInit() {
  }

}
