import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { CogLoaderComponent } from "app/components/cognian-components/cog-loader/cog-loader.component";
import { RegistrationService } from "app/CognianServices/registration.service";
import { Users, UserType, UserRegRights } from "app/CognianModels/User.Model";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { TypeOfPerson } from "app/CognianModels/AppProperties";
import { Router } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import { Person } from "app/CognianModels/Person";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  @ViewChild("stepper", null) stepper;
  @ViewChild("infoSwal", null) private infoSwal: SwalComponent;
  @ViewChild("errorSwal", null) private errorSwal: SwalComponent;
  password: string;
  confirmPassword: string;
  personRegCode: string = "";
  contactMethod: string = "";
  validCode: boolean = false;
  userInfoList: Person[] = new Array();
  selectedContact: Person = new Person();
  userItem: Person = new Person();
  canReg: boolean = false;
  hasRequestedCode: boolean = false;
  flag3: boolean = false;
  personID: string;
  user: Users = new Users();
  selectedIndex: number = 0;
  errorMessage: string = "";
  tempToken: string = "";
  constructor(
    public dialog: MatDialog,
    private regService: RegistrationService,
    private router: Router
  ) {}

  ngOnInit() {}
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);
  indexChange(event) {
    switch (event.selectedIndex) {
      case 1:
        this.getUserInfo();
        break;
    }
  }

  checkUserAccount() {
    this.errorMessage = "";
    if (!this.user.PersonID || this.user.PersonID == "") {
      this.errorMessage = "Please enter your ID number";
      return;
    }
    const dialogRefs = this.dialog.open(CogLoaderComponent, {
      disableClose: true,
      data: "Checking account state...",
    });

    this.regService.checkUserHasAccount(this.user.PersonID).subscribe(
      (result) => {
        dialogRefs.close();
        if (result != true) {
          this.canReg = true;
          this.changeSelectedIndex(1);
        } else {
          this.infoSwal.text =
            "You already have an account. To reset your password choose 'Reset Password' on the login page";
          this.infoSwal.fire();
          this.changeSelectedIndex(0);
        }
      },
      (error) => {
        dialogRefs.close();
      }
    );
  }
  onChange($event) {
    
  }
  getUserInfo() {
    const dialogRefs = this.dialog.open(CogLoaderComponent, {
      disableClose: true,
      data: "Looking for account details",
    });
    this.regService.getUserAssociates(this.user.PersonID).subscribe(
      (result) => {
        dialogRefs.close();
        if (result.length == 0) {
          this.infoSwal.text = "Unable to find info for provided ID";
          this.infoSwal.fire();
          this.changeSelectedIndex(0);
          return;
        }

        this.userInfoList = result;
      },
      (error) => {
        
        dialogRefs.close();
      }
    );
  }

  changeSelectedIndex(index: number) {
    this.selectedIndex = index;
    this.stepper.selectedIndex = this.selectedIndex;
  }
  sendVerificationCode() {
    this.errorMessage = "";
    if (!this.validateForm()) {
      return;
    }
    let toReg: Person = new Person();
    toReg.ID = this.user.PersonID;
    if ((this.contactMethod = "SMS")) {
      toReg.Cell = this.selectedContact.Cell;
      toReg.Email = "";
    } else {
      toReg.Email = this.selectedContact.Email;
      toReg.Cell = "";
    }
    const dialogRefs = this.dialog.open(CogLoaderComponent, {
      disableClose: true,
      data: "Generating your code",
    });
    this.regService.generateRegistrationCode(toReg).subscribe(
      (result) => {
        dialogRefs.close();
        this.changeSelectedIndex(2);
        this.infoSwal.text =
          "You will receive an SMS shortly with your registration Code.";
        this.infoSwal.fire();
      },
      (error) => {
        dialogRefs.close();
        this.errorSwal.fire();
      }
    );
  }
  navigateBack() {
    window.location.href = "#/login";
  }
  validateForm() {
    if (!this.user.PersonID || this.user.PersonID == "") {
      this.errorMessage = "Please Enter ID number first";
      return false;
    }
    if (!this.selectedContact || this.selectedContact == null) {
      this.errorMessage = "Please select contact method";
      return false;
    }
    if (this.contactMethod == "") {
      this.errorMessage = "Please select contact method";
      return false;
    }
    return true;
  }
  validateCode() {
    const dialogRefs = this.dialog.open(CogLoaderComponent, {
      disableClose: true,
      data: "Validating Code...",
    });
    this.regService.validateUserCode(this.personRegCode).subscribe(
      (result: string) => {
        dialogRefs.close();
        
        if (result.indexOf("$Token$") == -1) {
          this.infoSwal.text =
            "The code you entered is incorrect. Please try again";
          this.infoSwal.fire();
          return;
        }
        let tempToken = result.split("$Token$")[1];
        localStorage.setItem("token", tempToken);
        this.regService.confirmUserCode(this.personRegCode);
        
        this.populateUserRegObject();
        this.validCode = true;
      },
      (error) => {
        dialogRefs.close();
      }
    );
  }
  registerUser() {
    let toReg: Users = new Users();
    toReg.PersonID = this.user.PersonID;
    toReg.Pass = this.password;
    toReg.PersonType = this.mapPersonTypeToUserType(this.userItem.PersonType);
    toReg.UserID = this.userItem.Email;
    toReg.SchoolID = this.userItem.SchoolID;
    toReg.Rights = this.mapUserTypetoRights(this.userItem.PersonType);
    if (!this.validateUserRegObject(toReg)) {
      return;
    }
    const dialogRefs = this.dialog.open(CogLoaderComponent, {
      disableClose: true,
      data: "Creating Account...",
    });
    this.regService.registerUser(toReg).subscribe(
      (result) => {
        dialogRefs.close();
        this.infoSwal.text = "Your user account was successfully created.";
        this.infoSwal.fire();
        this.navigateBack();
      },
      (error) => {
        dialogRefs.close();
        this.errorSwal.fire();
      }
    );
  }
  validateUserRegObject(user: Users) {
    if (!this.password || !this.confirmPassword) {
      this.errorMessage = "Please enter Password";
      return false;
    }
    if (user.UserID == "") {
      this.errorMessage = "User Email is missig";
      return false;
    }
    if (user.Pass == "") {
      this.errorMessage = "Please enter Password";
      return false;
    }
    if (user.Pass != this.confirmPassword) {
      this.errorMessage = "Passwords do not match";
      return false;
    }
    if (user.Pass.length < 8) {
      this.errorMessage = "Password must be atleast 8 characters long";
      return false;
    }
    return true;
  }
  mapUserTypetoRights(personType) {
    switch (personType) {
      case "Parent":
        return UserRegRights.Guardian;
      case "Staff":
        return UserRegRights.Teacher;
      case "Student":
        return UserRegRights.Sudent;
      default:
        return UserRegRights.Guardian;
    }
  }
  mapPersonTypeToUserType(personType) {
    
    switch (personType) {
      case "Parent":
        return UserType.Parent;
      case "Staff":
        return UserType.Teacher;
      case "Student":
        return UserType.Student;
      default:
        return UserType.Parent;
    }
  }
  populateUserRegObject() {
    this.userItem = this.userInfoList.find((c) => c.ID == this.user.PersonID);
  }
}
