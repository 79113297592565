import { Component, OnInit, ViewChild } from "@angular/core";
import { AppProperties } from "app/CognianModels/AppProperties";
import { Router } from "@angular/router";
import { CogAuthService } from "app/CognianServices/cog-auth.service";
import { UserRegRights } from "app/CognianModels/User.Model";
import { DomSanitizer } from "@angular/platform-browser";
import * as myGlobals from "environments/globals";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { MatDialog } from "@angular/material";
import { CogLoaderComponent } from "../cognian-components/cog-loader/cog-loader.component";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { VerificationModalComponent } from "../modals/verification-modal/verification-modal.component";

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  allowedUsers: Array<string>;
  isVisible: boolean;
  groupName: String;
  hasSubMenu: boolean;
  subMenu: Array<object>;
}
export const ROUTES: RouteInfo[] = [
  // { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
  {
    path: "/newsfeed",
    title: "News Feed",
    icon: "library_books",
    class: "",
    allowedUsers: null,
    isVisible: false,
    groupName: "none",
    hasSubMenu: false,
    subMenu: [],
  },
  {
    path: "",
    title: "Academic Resources",
    icon: "assignment",
    class: "",
    allowedUsers: null,
    isVisible: false,
    groupName: "none",
    hasSubMenu: true,
    subMenu: [
      {
        title: "School Papers",
        icon: "assessment",
        path: "/academic",
        allowedUsers: null,
      },
      {
        title: "Cognian Resources",
        icon: "bubble_chart",
        path: "/cognianacademics",
        allowedUsers: null,
      },
    ],
  },
  {
    path: "",
    title: "Classroom",
    icon: "school",
    class: "",
    allowedUsers: null,
    isVisible: false,
    groupName: "none",
    hasSubMenu: true,
    subMenu: [
      {
        title: "Class Tests",
        icon: "assessment",
        path: "/main-exam",
        allowedUsers: ["STUDENT", "STAFF"],
      },
      {
        title: "Class Lessons",
        icon: "account_balance",
        path: "/lessons",
        allowedUsers: ["STUDENT", "STAFF"],
      },
    ],
  },
  {
    path: "/schoolresource",
    title: "School Documents",
    icon: "descriptiont",
    class: "",
    allowedUsers: null,
    isVisible: false,
    groupName: "none",
    hasSubMenu: false,
    subMenu: [],
  },
  // {
  //   path: "/upload",
  //   title: "Upload Resource",
  //   icon: "cloud_upload",
  //   class: "",
  //   allowedUsers: ["STAFF"],
  //   isVisible: false,
  //   groupName: "none",
  // },
  {
    path: "/user-profile",
    title: "My Profile",
    icon: "person",
    class: "",
    allowedUsers: null,
    isVisible: false,
    groupName: "none",
    hasSubMenu: false,
    subMenu: [],
  },
  {
    path: "/calendar",
    title: "School Calendar",
    icon: "date_range",
    class: "",
    allowedUsers: null,
    isVisible: false,
    groupName: "none",
    hasSubMenu: false,
    subMenu: [],
  },
  {
    path: "/cloud-storage",
    title: "School Management",
    icon: "home_work",
    class: "",
    allowedUsers: ["STORAGE_MANAGER"],
    isVisible: false,
    groupName: "none",
    hasSubMenu: true,
    subMenu: [
      {
        title: "Generate Report Cards",
        icon: "school",
        path: "/report-generate",
        allowedUsers: ["STAFF"],
      },
      {
        title: "Report card Settings",
        icon: "school",
        path: "/school-report",
        allowedUsers: ["STAFF"],
      },
      {
        title: "School Cloud Storage",
        icon: "backup",
        path: "/cloud-storage",
        allowedUsers: ["STORAGE_MANAGER"],
      },
    ],
  },

  // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
  // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
  // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
  // { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
  // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuGroups = [
    {
      name: "none",
      icon: "",
      visible: false,
    },
    {
      name: "Test",
      icon: "",
      visible: false,
    },
  ];

  schoolManagementSubMenu = [
    {
      path: "/cloud-storage",
      title: "School Cloud Storage",
      icon: "backup",
      class: "",
      allowedUsers: ["STORAGE_MANAGER"],
      isVisible: false,
    },
  ];
  menuItems: any[];
  user: string;
  schoolName: string;
  imagePath: any;
  constructor(
    private _sanitizer: DomSanitizer,
    private appdata: AppProperties,
    private router: Router,
    private auth: CogAuthService,
    private eventBus: NgEventBus,
    public dialog: MatDialog
  ) {}

  @ViewChild("messageSwal", null) private messageSwal: SwalComponent;
  createEvents() {
    this.eventBus.on("show-loader").subscribe((meta: MetaData) => {
      this.dialog.open(CogLoaderComponent, {
        disableClose: true,
        data: meta.data,
      });
    });

    this.eventBus.on("close-loader").subscribe((meta: MetaData) => {
      this.dialog.closeAll();
    });
    this.eventBus.on("show-success").subscribe((meta: MetaData) => {
      this.messageSwal.title = meta.data.title;
      this.messageSwal.text = meta.data.text;
      this.messageSwal.type = "success";
      this.messageSwal.fire().then((res) => {
        if (meta.data.onClose) meta.data.onClose();
      });
    });

    this.eventBus.on("show-error").subscribe((meta: MetaData) => {
      this.messageSwal.title = meta.data.title;
      this.messageSwal.text = meta.data.text;
      this.messageSwal.type = "error";
      this.messageSwal.fire().then((res) => {
        if (meta.data.onClose) meta.data.onClose();
      });
    });

    this.eventBus.on("show-verification").subscribe((meta: MetaData) => {
      this.showVerificationModal(meta.data);
    });
  }

  showVerificationModal(data) {
    const dialogRef = this.dialog.open(VerificationModalComponent, {
      width: window.screen.width > 700 ? "fit-content" : "100vw",
      maxWidth: window.screen.width > 700 ? "fit-content" : "100vw",
      height: window.screen.width > 700 ? "fit-content" : "100vh",
      data: data,
    });
  }

  successSwalOk() {}

  ngOnInit() {
    this.createEvents();
    if (this.appdata.school.SchoolLogo.FileName)
      this.imagePath = this._sanitizer.bypassSecurityTrustUrl(
        "data:image/" +
          this.appdata.school.SchoolLogo.extension.slice(1) +
          ";base64," +
          this.appdata.school.SchoolLogo.ContentBase64
      );
    else {
      this.imagePath = myGlobals.appLogo;
    }
    // let approvedMenuItems;
    // ROUTES.forEach(c=>
    //   {
    //     if(c.allowedUsers.filter(d=>d == UserRegRights.All))//Everyone is allowed to see this
    //     {
    //       approvedMenuItems =
    //     }
    //   });
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.user = localStorage.getItem("UserName");
    this.schoolName = this.appdata.school.SchoolName;
    let filterList = [];
    for (let c of this.menuItems) {
      if (!c.allowedUsers) {
        filterList.push(c);
      } else {
        for (let element of c.allowedUsers) {
          if (this.appdata.loggedInUser.Permisions.indexOf(element) != -1) {
            filterList.push(c);
            break;
          }
        }
      }
    }
    this.menuItems = filterList;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  logUserOut() {
    this.auth.Logout();
    this.router.navigate(["/login"]);
  }
}
