import { OnInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { Users, UserRegRights, UserType } from "../../CognianModels/User.Model";
import { CogAuthService } from "../../CognianServices/cog-auth.service";
import { AppProperties } from "../../CognianModels/AppProperties";
import { CogUserService } from "../../CognianServices/cog-user.service";
import { stringify } from "@angular/compiler/src/util";
import { CogStudentService } from "../../CognianServices/cog-student.service";
import { Student } from "../../CognianModels/Student";
import { CognianCommonService } from "../../CognianServices/cognian.common.service";
import { SchoolGroupService } from "app/CognianServices/school-group.service";
import { SchoolGroup } from "app/CognianModels/SchoolGroup";
import { GuardianService } from "app/CognianServices/guardian.service";
import { TeacherService } from "app/CognianServices/teacher.service";
import { Guardian } from "app/CognianModels/Guardian";
import { Teacher } from "app/CognianModels/Teacher";
declare var $: any;
@Component({
  selector: "app-login-cognian",
  templateUrl: "./login-cognian.component.html",
  styleUrls: [
    "./login-cognian.component.css",
    "./main.css",
    "./util.css",
    "./hamburgers.min.css",
    "./select2.min.css",
  ],
})
export class LoginCognianComponent implements OnInit {
  user: Users = new Users();
  constructor(
    private router: Router,
    private guardianCont: GuardianService,
    private teacherCont: TeacherService,
    private auth: CogAuthService,
    private appdata: AppProperties,
    private coguser: CogUserService,
    private cogStudent: CogStudentService,
    private cognianCommon: CognianCommonService,
    private schoolGroupService: SchoolGroupService
  ) {}
  validUser: boolean = true;
  backendOn: boolean = true;
  loading: boolean = false;
  errorMsg: string = "Incorrect Details";
  ngOnInit() {
    localStorage.clear();

    this.cognianCommon.CheckServerConnectivity().subscribe((result) => {
      this.backendOn = result;
    });
  }
  onLogIn() {
    this.loading = true;

    this.auth.LoginUser(this.user).subscribe(
      (result: any) => {
        if (result == null || result == "undefined") {
          alert("Wrong Username or Password");
          return;
        }
        localStorage.setItem("token", result.AccessToken);
        localStorage.setItem("Refreshtoken", result.RefreshToken);
        let claims = this.auth.extractAllClaims();
        let loggedInUser = new Users();
        loggedInUser.SchoolID = claims.SchoolID;
        loggedInUser.PersonID = claims.PersonID;
        loggedInUser.Rights = claims.role;
        loggedInUser.PersonType = claims.PersonType;
        loggedInUser.Permisions = claims.Permissions;
        this.appdata.loggedInUser = loggedInUser;
        this.appdata.userToken = result.AccessToken;
        this.appdata.schoolGroup = JSON.parse(claims.Groups);
        this.GetSchoolInfo(claims.SchoolID);
      },
      (error) => {
        this.backendOn = false;
        this.loading = false;
      }
    );
  }

  GetSchoolInfo(schoolId) {
    this.cognianCommon.GetSchoolInfo(schoolId).subscribe(
      (result) => {
        this.appdata.school = result;
        this.GetPersonalInfo();
      },
      (error) => {
        console.log(error);
        this.backendOn = false;
        this.loading = false;
      }
    );
  }

  GetPersonalInfo() {
    // if (this.appdata.loggedInUser.PersonType = UserType.Student) {
    //   console.log(this.appdata.loggedInUser);
    //   this.cogStudent.GetStudent().subscribe(result => {
    //     console.log(result);
    //     this.appdata.userObject = result;
    //     let st = result as Student;
    //     console.log(st);
    //     localStorage.setItem("UserName", st.Name + " " + st.Surname);
    //     //this.showNotification("top","right","Welcome Back "+st.Name + " "+st.Surname,"info")
    //     localStorage.setItem("loggedIn", '1');
    //     this.router.navigate(['/newsfeed']);
    //     this.loading = false;
    //   });
    // }

    switch (this.appdata.loggedInUser.PersonType.toString()) {
      case UserType.Student:
        this.cogStudent.GetStudent().subscribe((result) => {
          this.appdata.userObject = result;
          let st = result as Student;

          localStorage.setItem("UserName", st.Name + " " + st.Surname);
          localStorage.setItem("loggedIn", "1");
          this.router.navigate(["/newsfeed"]);
          this.loading = false;
        });
        break;
      case UserType.Parent:
        this.guardianCont
          .GetGuardianInfo(this.appdata.loggedInUser.PersonID)
          .subscribe((result) => {
            this.appdata.userObject = result;
            let guardian = result as Guardian;
            localStorage.setItem(
              "UserName",
              guardian.Name + " " + guardian.Surname
            );
            this.router.navigate(["/newsfeed"]);
            this.loading = false;
          });
        break;
      case UserType.Teacher:
        this.teacherCont
          .getTeacher(this.appdata.loggedInUser.PersonID)
          .subscribe((result) => {
            this.appdata.userObject = result;
            let teacher = result as Teacher;
            localStorage.setItem(
              "UserName",
              teacher.Name + " " + teacher.Surname
            );
            this.router.navigate(["/newsfeed"]);
            this.loading = false;
          });
        break;
    }
  }

  register() {
    this.router.navigate(["#/register"]);
  }
}
