import { Injectable } from '@angular/core';
import { CogWebHandlerService } from './cog-web-handler.service';
import { AppProperties } from 'app/CognianModels/AppProperties';

@Injectable({
  providedIn: 'root'
})
export class SchoolGroupService {
ControllName:String="SchoolGroup";
  constructor(private webHandler:CogWebHandlerService,private appdata:AppProperties) { }

  public GetUserGroups(UserID:String){
    
    return this.webHandler.GetRequest(this.ControllName+"/"+UserID+"/"+this.appdata.schoolID);
  }
}
