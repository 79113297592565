import { Component, OnInit, ViewChild } from "@angular/core";
import { Users } from "app/CognianModels/User.Model";
import { RegistrationService } from "app/CognianServices/registration.service";
import { MatDialog } from "@angular/material";
import { CogLoaderComponent } from "app/components/cognian-components/cog-loader/cog-loader.component";
import { UserService } from "app/CognianServices/user.service";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { Person } from "app/CognianModels/Person";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private userService: UserService,
    private registrationCont: RegistrationService,
    public dialog: MatDialog
  ) {}
  user: Users = new Users();
  @ViewChild("infoSwal", null) private infoSwal: SwalComponent;
  @ViewChild("errorSwal", null) private errorSwal: SwalComponent;
  @ViewChild("stepper", null) stepper;
  confirmPassword: string = "";
  password: string = "";
  validCode: boolean = false;
  selectedIndex = 0;
  errorMessage: string = "";
  userEmail: string = "";
  contactMethod: string = "";
  personCode: string = "";
  userContactList: Person[] = new Array();
  currentUser: Person = new Person();
  ngOnInit() {}
  navigateBack() {
    window.location.href = "#/login";
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  validateCode() {
    this.errorMessage = "";
    if (!this.personCode || this.personCode == "") {
      this.errorMessage = "Please enter a valid Code";
      return;
    }
    this.userService
      .checkConfirmationStatus(
        this.personCode.trim(),
        this.currentUser.ID.trim()
      )
      .subscribe((result: string) => {
        if (result == null) {
          this.infoSwal.text = "The confirmation code is incorrect";
          this.infoSwal.fire();
          return;
        }
        let tempToken = result.split("$Token$")[1];
        localStorage.setItem("token", tempToken);
        this.validCode = true;
      });
  }
  getAccountContact() {
    this.errorMessage = "";
    if (!this.userEmail || this.userEmail == "") {
      this.errorMessage = "Enter a valid Email address.";
      return;
    }
    const dialogRef = this.dialog.open(CogLoaderComponent, {
      disableClose: true,
      data: "Looking for account info...",
    });
    this.registrationCont.getUserByEmail(this.userEmail.trim()).subscribe(
      (result) => {
        
        dialogRef.close();
        if (!result) {
          this.errorMessage = "No Account info available";
          return;
        }
        this.currentUser = result;
        this.changeSelectedIndex(1);
      },
      (error) => {
        
        dialogRef.close();
      }
    );
  }

  changeSelectedIndex(index: number) {
    this.selectedIndex = index;
    this.stepper.selectedIndex = this.selectedIndex;
  }
  sendVerificationCode() {
    this.errorMessage = "";
    if (!this.contactMethod || this.contactMethod == "") {
      this.errorMessage = "Choose a contact method";
      return;
    }
    const dialogRef = this.dialog.open(CogLoaderComponent, {
      disableClose: true,
      data: "Generating Verification Code...",
    });

    this.userService
      .generateRegCode(this.currentUser, this.contactMethod.trim())
      .subscribe(
        (result) => {
          
          dialogRef.close();
          this.changeSelectedIndex(2);
          this.infoSwal.text =
            "Your verification code has been sent. You should receive it shortly";
        },
        (erorr) => {
          
          dialogRef.close();
        }
      );
  }

  validatePsswordData() {
    this.errorMessage = "";
    if (!this.password || this.password == "") {
      this.errorMessage = "Please enter a password";
      return false;
    }
    if (!this.confirmPassword || this.confirmPassword == "") {
      this.errorMessage = "Please enter both password and confirm password";
      return false;
    }
    if (this.password.length < 8) {
      this.errorMessage = "Make sure your password is more than 8 characters";
      return false;
    }
    return true;
  }
  resetPassword() {
    if (!this.validatePsswordData()) {
      return;
    }
    const dialogRef = this.dialog.open(CogLoaderComponent, {
      disableClose: true,
      data: "Checking...",
    });
    let user = new Users();
    user.Pass = this.password;
    user.UserID = this.userEmail;
    user.PersonID = this.currentUser.ID;
    this.userService.resetPassword(user).subscribe(
      (result) => {
        dialogRef.close();
        this.infoSwal.text = "Password Changed Successfully";
        this.infoSwal.type = "success";
        this.infoSwal.fire();
        this.navigateBack();
      },
      (error) => {
        dialogRef.close();
        this.errorSwal.text = "Unable to change your password at this time";
        this.errorSwal.fire();
      }
    );
  }
}
