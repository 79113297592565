import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { QuizGradingComponent } from "../quiz-grading/quiz-grading.component";

@Component({
  selector: "app-verification-modal",
  templateUrl: "./verification-modal.component.html",
  styleUrls: ["./verification-modal.component.scss"],
})
export class VerificationModalComponent implements OnInit {
  @Output() onNo: EventEmitter<any> = new EventEmitter();
  @Output() onYes: EventEmitter<any> = new EventEmitter();
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;
  constructor(
    public dialogRef: MatDialogRef<VerificationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: "";
      content: "";
      yesText: "";
      noText: "";
      onYesClick: any;
      onNoClick: any;
    }
  ) {
    this.lottieConfig = {
      path: "../assets/Animations/Question.json",
      autoplay: true,
      loop: true,
    };
  }
  handleAnimation(anim: any) {
    this.anim = anim;
  }
  ngOnInit() {}

  onYesClick() {
    if (typeof this.data.onYesClick === "function") {
      this.data.onYesClick();
      this.onYes.emit();
      this.dialogRef.close();
    }
  }

  onNoClick() {
    if (typeof this.data.onNoClick === "function") {
      this.data.onNoClick();
      this.onNo.emit();
      this.dialogRef.close();
    }
  }
}
