import { Injectable } from '@angular/core';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import * as CryptoJS from '../../../node_modules/crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CognianCryptoService {

  constructor() { }
  static encryptSecretKey:string="dasfasfsaddasdd";
  static encryptData(data) {

    try {
      console.log("Preparing to Encrypt Value");
      return CryptoJS.AES.encrypt(JSON.stringify(data), "CognianEncryptionKey").toString();
    } catch (e) {
      console.log(e);
    }
  }

  static decryptData(data) {

    try {
      
      console.log("Preparing to DE crypt Value");
      const bytes = CryptoJS.AES.decrypt(data, "CognianEncryptionKey");
      if (bytes.toString()) {
        console.log("Returning Data");
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}
