import { Injectable } from "@angular/core";
import { Users } from "../CognianModels/User.Model";
import { CogWebHandlerService } from "./cog-web-handler.service";
import { AppProperties } from "../CognianModels/AppProperties";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class CogAuthService {
  ControllerName: string = "Auth";
  ApplicationToken: string;
  cachedRequests: Array<HttpRequest<any>> = [];
  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }
  public retryFailedRequests(): HttpRequest<any> {
    return this.cachedRequests.pop();
  }
  constructor(
    private webHandler: CogWebHandlerService,
    private appdata: AppProperties,
    private router: Router
  ) {}

  RefreshUserToken() {
    let tokenPair = {
      AccessToken: localStorage.getItem("token"),
      RefreshToken: localStorage.getItem("Refreshtoken")
    };
    return this.webHandler.MakePost(
      this.ControllerName + "/V2/Refresh",
      tokenPair
    );
  }

  LoginUser(user: Users): Observable<string> {
    return this.webHandler.MakePost(
      this.ControllerName + "/V2/Authenticate",
      user,
      true
    );
  }
  Logout() {
    //this.appdata.userObject = null;
    //this.appdata.loggedInUser = null;
    //this.appdata.userToken= null;

    localStorage.clear();
    this.router.navigate(["/"]);
  }

  extractAllClaims() {
    let jwtHelper = new JwtHelperService();
    let claims = jwtHelper.decodeToken(localStorage.getItem("token"));
    return claims;
  }

  isLoggedIn() {
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem("token");
    console.log("Token Object Looks As Follows" + token);
    if (!token) return false;

    let expirationDate = jwtHelper.getTokenExpirationDate(token);
    let isExpired = jwtHelper.isTokenExpired(token);
    console.log("Expiration", expirationDate);
    console.log("isExpired", isExpired);
    console.log("token", token);

    return !isExpired;
  }

  GetUserToken(username, password) {
    let userObj = { UserID: username, Pass: password };
    return this.webHandler.MakePost("Auth/V2/Authenticate", userObj);
  }
}
