import { Injectable } from '@angular/core';
import { CogWebHandlerService } from './cog-web-handler.service';
import { AppProperties } from 'app/CognianModels/AppProperties';
import { CognianCommonService } from './cognian.common.service';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  ControlerName:String="Teacher";
  constructor(private webHandler:CogWebHandlerService,private appdata:AppProperties,private common:CognianCommonService) { }

  getTeacher(id)
  {
return this.webHandler.GetRequest(this.ControlerName+"/"+id,false);
  }
}
