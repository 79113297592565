import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NgEventBus } from "ng-event-bus";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { CalendarInputComponent } from "./modals/calendar-input/calendar-input.component";
import { VerificationModalComponent } from "./modals/verification-modal/verification-modal.component";
import { LottieAnimationViewModule } from "ng-lottie";
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SweetAlert2Module.forRoot(),
    LottieAnimationViewModule.forRoot(),
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    VerificationModalComponent,
  ],
  exports: [FooterComponent, NavbarComponent, SidebarComponent],
  providers: [NgEventBus],
  entryComponents: [VerificationModalComponent],
})
export class ComponentsModule {}
