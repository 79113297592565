import { Component } from "@angular/core";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Router } from "@angular/router";
import { CogAuthService } from "./CognianServices/cog-auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private auth: CogAuthService
  ) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(2 * 60);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(3 * 60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => (this.idleState = "No longer idle."));
    idle.onTimeout.subscribe(() => {
      //this.idleState = "Timed out!";
      //this.timedOut = true;
      // this.auth.Logout();
      // localStorage.clear();
      // this.router.navigate(["/login"]);
    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      this.idleState = "You've gone idle!";
      // redirect to home if idle for 30 minutes
    });
    idle.onTimeoutWarning.subscribe(
      countdown =>
        (this.idleState = "You will time out in " + countdown + " seconds!")
    );

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
}
