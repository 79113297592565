import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";

import "rxjs/add/operator/do";
import "rxjs/add/operator/catch";
import { AppProperties } from "app/CognianModels/AppProperties";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError, filter, finalize, take, switchMap } from "rxjs/operators";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   *
   */
  constructor(private appData: AppProperties) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): import("rxjs").Observable<HttpEvent<any>> {
    let token = localStorage.getItem("token");

    if (token && req.url.indexOf("refresh") == -1) {
      if (token.indexOf("Bearer") == -1) token = "Bearer " + token;
      const cloned = req.clone({
        setHeaders: { Authorization: token },
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
