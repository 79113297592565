import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "contentHide" })
export class ContentHidePipe implements PipeTransform {
  transform(value: string): string {
    let newStr: string = "";
    let len = value.length;
    if (len < 5) return value;
    let quater = Math.floor(len / 4);
    newStr = value.slice(quater);
    let hashed = "";
    for (let i = 0; i < quater; i++) {
      hashed += "*";
    }
    return hashed + newStr;
  }
}
