import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";

import { AppComponent } from "./app.component";
import { AgmCoreModule } from "@agm/core";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginCognianComponent } from "./CognianPages/login-cognian/login-cognian.component";
import { ParticlesComponent } from "./particles/particles.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from "angular2-moment";
import {
  MAT_DATE_LOCALE,
  MatTableModule,
  MatDialogModule,
} from "@angular/material";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { CogWebHandlerService } from "./CognianServices/cog-web-handler.service";
import { CognianInterceptorProviders } from "./CognianServices/Cognian Interceptors/CognianInterceptors";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";

import { FullCalendarModule } from "@fullcalendar/angular";
import { CdkTableModule } from "@angular/cdk/table";
import { RegisterComponent } from "./CognianPages/register/register.component";
import { MatStepperModule } from "@angular/material/stepper";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MaterialModule } from "./Material.Module";
import { CogLoaderComponent } from "./components/cognian-components/cog-loader/cog-loader.component";
import { ForgotPasswordComponent } from "./CognianPages/forgot-password/forgot-password.component";
import { ContentHidePipe } from "./CognianModels/CustomPipes/content-hide-pipe";
import { ChangeGuardService } from "./CognianServices/change-guard.service";
import { SignaturePadModule } from "angular2-signaturepad";
@NgModule({
  imports: [
    SignaturePadModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    SweetAlert2Module.forRoot(),
    MatTableModule,
    CdkTableModule,
    RouterModule,
    AppRoutingModule,
    MatDialogModule,
    MaterialModule,
    MomentModule,
    NgxMaterialTimepickerModule,
    // MDBBootstrapModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    // BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: "YOUR_GOOGLE_MAPS_API_KEY",
    }),
    FullCalendarModule,
    MatStepperModule,
    MatIconModule,
    MatButtonModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginCognianComponent,
    ParticlesComponent,
    RegisterComponent,
    CogLoaderComponent,
    ForgotPasswordComponent,
    ContentHidePipe,
  ],
  entryComponents: [CogLoaderComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    CogWebHandlerService,
    CognianInterceptorProviders,
    ChangeGuardService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
