import { Injectable } from '@angular/core';
import { AppProperties } from '../CognianModels/AppProperties';
import { CogWebHandlerService } from './cog-web-handler.service';
import { Users } from '../CognianModels/User.Model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CogUserService {

  ControllerName:string="User";
  constructor(private appdata:AppProperties,private webHandler:CogWebHandlerService) { }

  GetUserData():Observable<Users>
  {
    return this.webHandler.MakePost(this.ControllerName+"/Login",this.appdata.loggedInUser);
  }
}
