export class Users{
    UserID:string;    
    Pass:string;
    StaffID:string;
    PersonID:string;    
    PersonType:UserType;
    SchoolID:string;
    AccStat:boolean;
    AccNotes:string;
    Rights:UserRegRights;
    Permisions:Array<String>;
}
export enum UserType{
Student="Student",
Parent="Parent",
Teacher="Staff",

}

export enum UserRegRights
{
    CognianAdmin = "CognianAdmin", SysAdmin = "SysAdmin", DepartmentHead="DepartmentHead", Teacher="Teacher", adUser="adUser", Sudent="Sudent", Guardian="Guardian",All="All"
}