import { TypeOfPerson } from "./AppProperties";
import { Race, sex, Language, Address } from "./Student";

export class Person {
  ID: string;
  Name: string;
  Surname: string;
  PersonType: TypeOfPerson;
  PersonRace: Race;
  PersonSex: sex;
  PersonLanguage: Language;
  DOB: Date;
  Email: string;
  Cell: string;
  PersonAddress: Address;
  SchoolID: string;
}
