import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/do";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { CogAuthService } from "../cog-auth.service";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   *
   */
  constructor(private auth: CogAuthService) {}

  intercept(
    req: import("@angular/common/http").HttpRequest<any>,
    next: import("@angular/common/http").HttpHandler
  ): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
    return next.handle(req).do(
      (event: HttpEvent<any>) => {
        
        return;
        // if (event instanceof HttpResponse) {
        //   // do stuff with response if you want
        // }
      },
      (err: any) => {
        
        if (req.url.indexOf("refresh") == -1) {
          console.log("Logging Out User");
          // this.auth.Logout();

          return; //Logout Here
        }

        if (err instanceof HttpErrorResponse) {
          console.log("Error Caught in interceptor");
          if (err.status === 401) {
            console.log("UnAuthorized Error");
            return this.auth.RefreshUserToken().subscribe(
              result => {
                localStorage.setItem("token", result.AccessToken);
                localStorage.setItem("Refreshtoken", result.RefreshToken);
                const cloned = req.clone({
                  setHeaders: { Authorization: result.AccessToken }
                });
                return next.handle(cloned);
              },
              error => {
                console.log("Refresh Failed we need to logout now");
              }
            );
          } else {
            //Handle other errors here
          }
        }
      }
    );
  }
}
