import { Users } from "./User.Model";
import { Injectable } from "@angular/core";
import { CognianCryptoService } from "../CognianServices/cognian-crypto.service";
import { SchoolGroup } from "./SchoolGroup";
import { School } from "./school";
@Injectable({
  providedIn: "root",
})
export class AppProperties {
  // EdnpointUrl: string =
  //   "http://test.cognian.co.za/api";
  EdnpointUrl: string = "https://test.cognian.io/api";
  //loggedInUser:Users;

  //userObject:object;

  private _school: School;
  public get school(): School {
    if (this._userToken == "" || this._userToken == null) {
      this.GetData();
    }
    return this._school;
  }
  public set school(v: School) {
    this._school = v;
    this.SaveData();
  }

  public static NumberToSet: Number = 50;
  private _userToken: string;
  public get userToken(): string {
    if (this._userToken == "" || this._userToken == null) {
      this.GetData();
    }
    return this._userToken;
    //return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImRhbmllbCIsIm5iZiI6MTU2OTQzNTM4MCwiZXhwIjoxNTcwMDQwMTgwLCJpYXQiOjE1Njk0MzUzODAsImlzcyI6Imh0dHBzOi8vY29nbmlhbnZtLnVrd2VzdC5jbG91ZGFwcC5henVyZS5jb20iLCJhdWQiOiJodHRwczovL2NvZ25pYW52bS51a3dlc3QuY2xvdWRhcHAuYXp1cmUuY29tIn0.PhJaKO4jbLhAVG0Wt73QE1F4kD6zI1_ImEq22lok6Mo";
  }
  public set userToken(v: string) {
    this._userToken = v;
  }

  private _newsFeedIndex: number;
  public get newsFeedIndex(): number {
    if (this._newsFeedIndex == null || this._newsFeedIndex == 0) this.GetData();
    if (this._newsFeedIndex == null || this._newsFeedIndex == 0) return 99000;
    return this._newsFeedIndex;
  }
  public set newsFeedIndex(v: number) {
    this._newsFeedIndex = v;
    this.SaveData();
  }

  private _newsFeedDate: Date;
  public get newsFeedDate(): Date {

    if (this._newsFeedDate == null) this.GetData();
    if (!this._newsFeedDate) {
      let date = new Date();
      date.setFullYear(date.getFullYear() - 20);
      return date;
    }
    return this._newsFeedDate;
  }
  public set newsFeedDate(v: Date) {
    this._newsFeedDate = v;
    this.SaveData();
  }

  private _userObject: object = { none: "none" };
  public get userObject(): object {
    if (this._userObject) return this._userObject;
    if (!this.DataExist) return null;
    this._userObject = CognianCryptoService.decryptData(
      localStorage.getItem("Data")
    ).json()._userObject;
    return this._userObject;
  }
  public set userObject(v: object) {
    this._userObject = v;
    this.SaveData();
  }

  public GetData() {
    let appdata = new AppProperties();
    appdata = CognianCryptoService.decryptData(localStorage.getItem("Data"));
    this._loggedInUser = appdata._loggedInUser;
    this._schoolGroup = appdata._schoolGroup;
    this._schoolID = appdata._schoolID;
    this._userObject = appdata._userObject;
    this._userToken = appdata._userToken;
    this._newsFeedIndex = appdata._newsFeedIndex;
    this._school = appdata._school;
    this._newsFeedDate = appdata._newsFeedDate;
  }

  public SaveData() {
    let appdata = new AppProperties();
    appdata._school = this._school;
    appdata._loggedInUser = this._loggedInUser;
    appdata._schoolGroup = this._schoolGroup;
    appdata._schoolID = this._schoolID;
    appdata._userObject = this._userObject;
    appdata._userToken = this._userToken;
    appdata._newsFeedIndex = this._newsFeedIndex;
    appdata._newsFeedDate = this._newsFeedDate;
    localStorage.setItem("Data", CognianCryptoService.encryptData(appdata));
  }
  private MakeData(): object {
    return {
      loggedInUser: this._loggedInUser,
      userObject: this._userObject,
      userToken: this._userToken,
      schoolGroup: this._schoolGroup,
    };
  }
  private _loggedInUser: Users;

  public get loggedInUser(): Users {
    if (!this._loggedInUser) this.GetData();
    return this._loggedInUser;
  }

  private DataExist(): boolean {
    if (localStorage.getItem("Data") == null) return false;
    return true;
  }
  public set loggedInUser(v: Users) {
    this._loggedInUser = v;

    this.SaveData();
  }

  private _schoolID: string = "KEMP01";
  public get schoolID(): string {
    if (this._schoolID == "" || this._schoolID == null) this.GetData();
    return this._schoolID;
  }
  public set schoolID(v: string) {
    this._schoolID = v;
    this.SaveData();
  }

  private _schoolGroup: SchoolGroup[];
  public get schoolGroup(): SchoolGroup[] {
    if (this._schoolGroup == null) this.GetData();
    return this._schoolGroup;
  }
  public set schoolGroup(v: SchoolGroup[]) {
    this._schoolGroup = v;
    this.SaveData();
  }
}

export enum TypeOfPerson {
  NA = 0,
  Student = 1,
  Guardian,
  Staff, //has the same names as the tables in the db
}
